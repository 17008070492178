import React from 'react'
// import callporwal from "../assets/final cards for mayur/committee cards/callbutton.png"
// import chatporwal from "../assets/final cards for mayur/committee cards/chatbutton.png"
// import messageporwal from "../assets/dashbordBtn/messageporwal.png"
// import viewporwal from "../assets/final cards for mayur/committee cards/profilebutton.png"
// import GodLogo from "../assets/final cards for mayur/committee cards/samajbutton.png"
// import img1 from "../assets/Doctors/1.png"
// import img2 from "../assets/Doctors/2.png"
// import img3 from "../assets/Doctors/3.png"
// import img4 from "../assets/Doctors/4.png"
// import img5 from "../assets/Doctors/5.png"
// import img6 from "../assets/Doctors/6.png"
// import img7 from "../assets/Doctors/7.png"
// import img8 from "../assets/Doctors/8.png"
// import img9 from "../assets/Doctors/9.png"
// import img10 from "../assets/Doctors/10.png"
// import img11 from "../assets/Doctors/11.png"
// import img12 from "../assets/Doctors/12.png"
// import img13 from "../assets/Doctors/13.png"
// import img14 from "../assets/Doctors/14.png"
// import img15 from "../assets/Doctors/15.png"
// import img16 from "../assets/Doctors/16.png"
// import img17 from "../assets/Doctors/17.png"
// import img18 from "../assets/Doctors/18.png"
// import img19 from "../assets/Doctors/19.png"
// import img20 from "../assets/Doctors/20.png"
// import img21 from "../assets/Doctors/21.png"
// import img22 from "../assets/Doctors/22.png"
// import img23 from "../assets/Doctors/23.png"
// import img24 from "../assets/Doctors/24.png"
// import img25 from "../assets/Doctors/25.png"
// import img26 from "../assets/Doctors/26.png"
// import img27 from "../assets/Doctors/27.png"
// import img28 from "../assets/Doctors/28.png"
// import img29 from "../assets/Doctors/29.png"
// import img30 from "../assets/Doctors/30.png"
// import img31 from "../assets/Doctors/31.png"
// import img32 from "../assets/Doctors/32.png"
// import img33 from "../assets/Doctors/33.png"
// import img34 from "../assets/Doctors/34.png"
// import img35 from "../assets/Doctors/35.png"
// import img36 from "../assets/Doctors/36.png"
// import img37 from "../assets/Doctors/37.png"
// import img38 from "../assets/Doctors/38.png"
// import img39 from "../assets/Doctors/39.png"
// import madhaviDabakara from "../assets/Doctors/madhaviDabakara.png"

import DoctorIcon from "../assets/membercard/DoctorIcon.png"
import { appdata } from '../constants/appSamajdata'
import { doctorList } from '../constants/staticData'

// const images = [
//     img1,
//     img2,
//     img3,
//     img4,
//     img5,
//     img6,
//     img7,
//     img8,
//     img9,
//     img10,
//     img11,
//     img12,
//     img13,
//     img14,
//     img15,
//     img16,
//     img17,
//     img18,
//     img19,
//     img20,
//     img21,
//     img22,
//     img23,
//     img24,
//     img25,
//     img26,
//     img27,
//     img28,
//     img29,
//     img30,
//     img31,
//     img32,
//     img33,
//     img34,
//     img35,
//     img36,
//     img37,
//     img38,
//     img39
// ];
const { main_heading, Logo, HeadPerson } = appdata
export default function DoctorCard() {


    return (
        <>
            {doctorList.map((doctor, index) => {
                return (
                    <>
                        <div key={index} className=' w-[320px]   shadow-2xl m-4  rounded-b-3xl' >
                            <div className='doctor-card-bg-color-bottom relative text-center p-2  w-full h-[70px] rounded-t-3xl '>
                                <h2 className=' font-bold'>{main_heading}</h2>
                                <img className=" w-[4rem] h-[4rem] left-[40%] absolute  ring-2 rounded-full flex-none bg-blue-400" src={'https://tse3.mm.bing.net/th?id=OIP.d91ova9TzxPv09I0cagaRwHaHa&pid=Api&P=0&w=300&h=300'} alt="" loading="lazy" />
                            </div>
                            <div className='comittee-card-bg-color-bottom w-full h-[125px] pt-6 text-center rounded-b-3xl'>
                                <div className=' w-full flex flex-col justify-center'>
                                    <h1 className=' text-[#F7F014] font-semibold'>{doctor.name}</h1>
                                    <small className='  w-100 px-5 py-[0.1rem] h-[40px] bg-yellow-400  font-semibold'>{doctor.specialization}</small>
                                    {/* <div className=' flex flex-col items-center  justify-start    text-[12px] font-bold text-white'>
                            <div className=' flex flex-col   justify-start    text-[12px] font-bold text-white'>
                                <div className=' flex '><p className=' w-[100px] text-start ' >गोत्र </p> <p>: {user.gotra}</p> </div>
                                <div className=' flex'><p className=' w-[100px] text-start ' >वर्तमान शहर </p> <p>: {user.city.name}</p> </div>
                                <div className=' flex'><p className=' w-[100px] text-start ' >व्यवसाय </p> <p>: {user.occupation}</p> </div>
                                <div className=' flex  '><p className=' w-[100px] text-start ' >पिताजी का नाम </p> <p>: {user.fatherName}</p> </div>
                            </div>
                        </div> */}
                                </div>
                                <div className=' flex justify-start  gap-4 px-6   rounded-full shadow-2xl    items-end '>
                                    <img className='w-16 h-16' src={DoctorIcon} alt="QR Code" />
                                </div>
                            </div>
                        </div>
                    </>)
            })}

        </>
    )
}
