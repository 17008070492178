import React, { useEffect, useState, } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import logoutBtn from "../assets/dashbordBtn/logout.png";
import home from "../assets/dashbordBtn/home.png";
import vishalprofile from "../assets/membersimegas/vishalprofile.jpg";
import { useDispatch, useSelector } from "react-redux"
import { Domain } from '../ApiConfig';
import { handleLogout } from '../store/store';
import { logout } from "../service/Services"
import { logOutUser } from '../Redux/authSlicer';
import LoadingModal from '../Forms/LoadingModel';
import { appdata } from '../constants/appSamajdata';

export default function Header() {
    const { section } = useParams();
    const [headerFix, setHeaderFix] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch()
    let profilePhoto, name;

    let user = useSelector(state => state?.auth?.user);

    console.log('user', user)

    if (user?.userType === 4) {
        profilePhoto = user?.photos?.profilePhoto;
        name = user?.personalInformation?.name
    } else {
        profilePhoto = user?.profilePhoto;
        name = user?.name
    }



    const navigation = [
        { name: 'Dashboard', href: '#', current: true },
        { name: 'Team', href: '#', current: false },
        { name: 'Projects', href: '#', current: false },
        { name: 'Calendar', href: '#', current: false },
    ];

    useEffect(() => {
        const handleScroll = () => {
            setHeaderFix(window.scrollY > 80);
        };
        window.addEventListener('scroll', handleScroll);
        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // const logOuthandler = async () => {
    //     setLoading(true)
    //     try {
    //         // const isLoggedOut = await logout()
    //         const isLoggedOut = localStorage.removeItem("access_token")
    //         handleLogout()
    //         dispatch(logOutUser())
    //         navigate('/');

    //     } catch (error) {

    //     } finally {
    //         setLoading(false)
    //     }

    //     // handleLogout
    // }
    const onLogout = () => {
        // Dispatch any logout related actions here if needed
        // For example, clear auth state in Redux store
        // dispatch(clearAuth());

        // Handle the logout process and navigation
        handleLogout(dispatch, navigate);
    };
    return (
        <>
            <LoadingModal show={isLoading} text={'Logout...'} />
            <div className={`py-4 w-full ${headerFix ? 'is-fixed bg-white text-red-700 ' : 'bg-transparent text-white  '}`}>
                <div className={`text-center overflow-hidden  `}>
                    <div className="animate-slideRight top-header inline-block text-xl text-Gradient-child-cho font-bold">
                        {appdata["main_heading"]}
                    </div>
                </div>
                <div className="container mx-auto px-4">
                    <div className="flex items-center justify-between">
                        <div className='flex items-center'>
                            {/* {isLoading ? ( */}
                            <button
                                onClick={() => setDropdownOpen(!isDropdownOpen)}
                                className="flex items-center min-w-0 gap-x-2"
                            >
                                <img
                                    className="h-16 w-16 rounded-full flex-none bg-gray-50"
                                    src={profilePhoto ? Domain + profilePhoto : vishalprofile}
                                    alt=""
                                    loading="lazy"
                                />
                                <div className="min-w-0 flex-auto">
                                    <p className="text-[15px] md:text-xl leading-5 font-bold tracking-tight text-gray-900"> स्वागत -: {name}</p>
                                </div>
                            </button>
                            {/* Dropdown Content */}
                            {isDropdownOpen && (
                                <div onClick={() => setDropdownOpen(!isDropdownOpen)} className="absolute right-100 top-[6.5rem] mt- z-50 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <Link to="/dashboard/profileEdit" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Your Profile</Link>
                                    <Link to="/dashboard/lagahistory" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Laga History</Link>
                                    <Link to="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Settings</Link>
                                    <Link to="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Sign out</Link>
                                </div>
                            )}
                        </div>
                        <div className="flex items-center text-black">
                            <Link to={`${user?.userType == 4 ? "/matrimony" : "/dashboard"}`} className='me-2'><img className=' w-[50px] md:w-[70px]' src={home} /></Link>
                            <button onClick={onLogout}><img className=' w-[50px] md:w-[70px]' src={logoutBtn} /></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
