import { FaFileExport } from "react-icons/fa"
import Logo from "../assets/login card/logo.png"
import HeadPerson from "../assets/login card/headPerson.png"


export const appdata = {
    main_heading: "गहोई वैश्य समाज इंदौर",
    swagat_sms: "गहोई वैश्य समाज इंदौर में आपका स्वागत है",
    Logo: Logo,
    HeadPerson
}
