import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { appdata } from '../constants/appSamajdata';

// import Logo from "../assets/login card/bhagwancustamLogo.png"

const { main_heading, Logo, HeadPerson } = appdata
const MySwal = withReactContent(Swal);

const showSuccessAlert = (message, custamLogo) => {
    MySwal.fire({
        html: (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <img src={custamLogo || Logo} alt="custamLogo" style={{ width: '100px', marginBottom: '20px' }} />
                <div style={{ fontSize: '3em', color: 'green', marginBottom: '20px' }}>✔️</div>
                <p>{message}</p>
            </div>
        ),
        showConfirmButton: true, // Show confirm button if needed
        customClass: {
            popup: 'my-custom-popup', // Custom class for further styling if needed
        },
    });
};


const showErrorAlert = (message, custamLogo) => {
    MySwal.fire({
        html: (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <img src={custamLogo || Logo} alt="custamLogo" style={{ width: '100px', marginBottom: '20px' }} />
                <div style={{ fontSize: '3em', color: 'green', marginBottom: '20px' }}>❌</div>
                <p>{message}</p>
            </div>
        ),
        showConfirmButton: true, // Show confirm button if needed
        customClass: {
            popup: 'my-custom-popup', // Custom class for further styling if needed
        },
    });
};
const showImgInSwal = (text, img) => {
    MySwal.fire({
        html: (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <img src={img} alt="custamLogo" style={{ width: '200px', marginBottom: '20px' }} loading='lazy' />
                {/* <div style={{ fontSize: '3em', color: 'green', marginBottom: '20px' }}>✔️</div> */}
                <p>{text}</p>
            </div>
        ),
        showConfirmButton: true, // Show confirm button if needed
        customClass: {
            popup: 'my-custom-popup', // Custom class for further styling if needed
        },
    });
}
export {
    showSuccessAlert,
    showErrorAlert,
    showImgInSwal
}