
import API from "./API";
// const accessToken = sessionStorage.getItem("access_token")
// console.log(accessToken)
const token = () => {
    const accessToken = localStorage.getItem("access_token")
    return `Bearer ${accessToken}`;
}
export const addMatrimonyProfile = async (formData) => {
    const authorizationHeader = token();
    try {
        const response = await API.post('/matrimony/addMatrimonyProfile', formData, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
};

export const getCitysOnInput = async (formData) => {
    const authorizationHeader = token();
    const response = await API.post('/cities/search', formData,
        {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        }).catch((error) => {
            return 'regisration failed.'
        }

        )
    return response.data;
}
// member ***************
export const getMember = async (data, requestOptions) => {
    // const accessToken = token();
    // console.log(accessToken) // Replace with your token retrieval logic
    // const authorizationHeader = `Bearer ${accessToken}`;
    requestOptions.headers['Authorization'] = token();
    try {
        const response = await API.post('/searchMemberForMemberPage', data, requestOptions);
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}

export const upDateMember = async (formData, userType, requestOptions) => {
    // const accessToken = sessionStorage.getItem("access_token");
    // console.log(accessToken) // Replace with your token retrieval logic
    // const authorizationHeader = `Bearer ${accessToken}`;
    // console.log("Authorization Header:", authorizationHeader);
    requestOptions.headers['Authorization'] = token();

    console.log("userType", userType)

    const apiUrl = userType != 4 ? '/updateMember' : '/matrimony/updateMatrimony'

    try {
        const response = await API.put(apiUrl, formData, requestOptions);
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
};

// ***************************************


// export const isUserLogin = async (apiEndPoint, formData) => {
//     // const accessToken = sessionStorage.getItem("access_token");
//     // console.log(accessToken) // Replace with your token retrieval logic
//     const authorizationHeader = token();
//     console.log('apiEndPoint, formData', apiEndPoint, formData)
//     const response = await API.get(apiEndPoint, formData,
//         {
//             headers: {
//                 'Authorization': authorizationHeader,
//                 'Content-Type': 'multipart/form-data'
//             }
//         }).catch((error) => {
//             return error
//         }
//         )
//     return response ? response.data : {}
// }

export const addNews = async (formData) => {
    const authorizationHeader = token();

    try {
        const response = await API.post('/addnews', formData, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
};

export const searchNewsForNewsPage = async (data,) => {
    const authorizationHeader = token();
    try {
        const response = await API.post('/searchNewsForNewsPage', data, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}

export const shockNewNotification = async () => {
    const authorizationHeader = token();
    try {
        const response = await API.get('/shockNewNotifications', {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}
// /dharmashalBooking
export const bookDharmashala = async (formData) => {
    const authorizationHeader = token();
    try {
        const response = await API.post('/dharmashalBooking', formData, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}
export const getDharmashala = async () => {

    const authorizationHeader = token();
    try {
        const response = await API.get('/getDharmashala', {
            headers: {
                'Authorization': authorizationHeader,
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}
export const logout = async () => {
    const authorizationHeader = token();
    try {
        const response = await API.post('/logout', {}, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        sessionStorage.removeItem('access_token');
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}

export const addAchivement = async (formData) => {
    const authorizationHeader = token();
    try {
        const response = await API.post('/addAchivement', formData, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
};

export const getSAchivement = async () => {
    const authorizationHeader = token();
    try {
        const response = await API.get('/getAchivement', {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return Promise.reject(error.message);
    }
}



export const addsamajcalenderEvent = async (formData) => {
    const authorizationHeader = token();
    try {
        const response = await API.post('/addsamajEvent', formData, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
};

export const getSamajcalenderEvent = async () => {
    const authorizationHeader = token();
    try {
        const response = await API.get('/getsamajEvent', {
            headers: {
                'Authorization': authorizationHeader,

            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}
// /getsamajEvent


export const addSuggession = async (formData) => {
    const authorizationHeader = token();
    try {
        const response = await API.post('/addsuggesstion', formData, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}
export const replyTosuggestions = async (data) => {
    const authorizationHeader = token();

    try {
        const response = await API.patch('/suggetions-Reply', data, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }

}

export const getSuggession = async () => {
    const authorizationHeader = token();
    try {
        const response = await API.get('/getsuggesstion', {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}

export const getSuggessionById = async () => {
    const authorizationHeader = token();
    try {
        const response = await API.get('/suggetions-byid', {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}

export const addDonation = async (formData) => {
    const authorizationHeader = token();
    try {
        const response = await API.post('/addDonation', formData, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
};

export const getDonations = async () => {
    const authorizationHeader = token();
    try {
        const response = await API.get('/getDonation', {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}

export const memberScanByQr = async (Data) => {

    try {
        const response = await API.post('/memberScanByQr', Data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
};

export const updateMemberStatus = async (formData) => {
    const authorizationHeader = token();

    try {
        const response = await API.put('/update-member-status', formData, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }

}

// /memberStatusActivation


export const searchMemberForMemberByAdmimForActivation = async (data, requestOptions) => {
    // const authorizationHeader = token();
    requestOptions.headers['Authorization'] = token();
    try {
        const response = await API.post('/admin/member/activation', data, requestOptions);
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}

export const getMatrimony = async (data, requestOptions) => {
    // const accessToken = token();
    // console.log(accessToken) // Replace with your token retrieval logic
    // const authorizationHeader = `Bearer ${accessToken}`;
    requestOptions.headers['Authorization'] = token();
    try {
        const response = await API.post('/matrimony/searchMatrimonyForMatrimonyPage', data, requestOptions);
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}

// laga
export const generateLaga = async (formData) => {
    const authorizationHeader = token();
    try {
        const response = await API.post('/laga/generateLaga', formData, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
};

export const getLagabyMemberCode = async () => {
    const authorizationHeader = token();
    try {
        const response = await API.get('/laga/getLaga', {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return Promise.reject(error.message);
    }
};

export const searchMemberForPaymentLaga = async (data, requestOptions) => {
    // const authorizationHeader = token();
    requestOptions.headers['Authorization'] = token();
    try {
        const response = await API.post('/laga/admin/searchMemberForPaymentLaga', data, requestOptions);
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}


export const updateLaga = async (formData) => {
    const authorizationHeader = token();

    try {
        const response = await API.put('/laga/admin/updateLaga', formData, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }

}

export const ScanMatrimonyByQr = async (Data) => {

    try {
        const response = await API.post('/matrimony/scanMatrimonyByQr', Data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
};

export const genarateMatrimonySharebleQr = async (Data) => {
    const authorizationHeader = token();

    try {
        const response = await API.post('/matrimony/genarateMatrimonySharebleQr', Data, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
};
// ScanMatrimonyShareableQr

export const ScanMatrimonyShareableQr = async (Data) => {

    try {
        const response = await API.post('/matrimony/ScanMatrimonyShareableQr', Data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
};

// *************Gatividhi*********************

export const addNewGatividhi = async (formData) => {
    const authorizationHeader = token();
    try {
        const response = await API.post('/gatividhi/add-new-gatividhi', formData, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}
// /get-gatividhi

export const getGatividhi = async () => {
    const authorizationHeader = token();
    try {
        const response = await API.get('/gatividhi/get-gatividhi', {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}

//************************************* */
export const getgallerys = async () => {
    const authorizationHeader = token();
    try {
        const response = await API.get('/gallery/get-gallery', {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}


export const getVaishyaNews = async () => {
    const authorizationHeader = token();
    try {
        const response = await API.get('/vaishyanews/get-VaishyaNews', {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}

export const searchMatrimonyForgalleryPage = async (data, requestOptions) => {
    // const accessToken = token();
    // console.log(accessToken) // Replace with your token retrieval logic
    // const authorizationHeader = `Bearer ${accessToken}`;
    requestOptions.headers['Authorization'] = token();
    try {
        const response = await API.post('/vivahGallery/searchMatrimonyForgalleryPage', data, requestOptions);
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}

export const ShowIntrest = async (reciverId) => {
    const authorizationHeader = token();

    try {
        const response = await API.post('/intrest/show-intrest', reciverId, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return Promise.reject(error.message);
    }
}



export const samajReport = async (samaj) => {
    const authorizationHeader = token();
    // const params = new URLSearchParams(formData).toString();
    const url = `/admin/get-allSamajReport}`;
    try {
        const response = await API.post('/admin/get-allSamajReport', { samaj }, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return Promise.reject(error.message);
    }
};
export const intrestReport = async () => {
    const authorizationHeader = token();

    try {
        const response = await API.post('/admin/get-intrest', {}, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return Promise.reject(error.message);
    }
};
export const deleteIntrestRequest = async (id) => {
    const authorizationHeader = token();
    try {
        const response = await API.delete(`/intrest/delete-request/${id}`, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return Promise.reject(error.message);
    }
};

// /get-intrest
export const getIntrestedProfileBothSide = async () => {
    const authorizationHeader = token();
    try {
        const response = await API.get('/intrest/get-intrest', {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return Promise.reject(error.message);
    }
};

export const isMobileNumberRegisterd = async (mobileNumber) => {
    ;
    try {
        const response = await API.post('/check-mobileNumber', mobileNumber, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data;
    } catch (error) {
        console.log(error);
        return Promise.reject(error?.message);
    }
};

export const getMatriMonyByMembershipID = async (Data) => {
    const authorizationHeader = token();

    console.log("get-MatriMonyByMembershipID authorizationHeader", authorizationHeader)
    try {
        const response = await API.post('/matrimony/get-MatriMonyByMembershipID', Data, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
};
export const getMemberByMembershipID = async (Data) => {
    const authorizationHeader = token();

    console.log("get-MatriMonyByMembershipID authorizationHeader", authorizationHeader)
    try {
        const response = await API.post('/get-memberByMembershipID', Data, {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
};

export const isUserLogin = async () => {
    const authorizationHeader = token();
    try {
        const response = await API.get('/isUserLogin', {
            headers: {
                'Authorization': authorizationHeader,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        return Promise.reject(error.message);
    }
}



// 