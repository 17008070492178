import React, { useEffect, useLayoutEffect, useState, useMemo } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';

import committee from "../../../assets/dashbordBtn/committee.png";
import members from "../../../assets/dashbordBtn/members.png";
import mandirdarshan from "../../../assets/dashbordBtn/mandirdarshan.png";
import rishtey from "../../../assets/dashbordBtn/rishtey.png";
import bookdharmashala from "../../../assets/dashbordBtn/bookdharmashala.png";
import programs from "../../../assets/dashbordBtn/programs.png";
import bloodbank from "../../../assets/dashbordBtn/bloodbank.png";
import donations from "../../../assets/dashbordBtn/donations.png";
import achievements from "../../../assets/dashbordBtn/achievements.png";
import samajcalender from "../../../assets/dashbordBtn/samajcalender.png";
import doctor from "../../../assets/dashbordBtn/doctoreBtn.png";
import promotion from "../../../assets/dashbordBtn/promotion.png";
import complaints from "../../../assets/dashbordBtn/complaints.png";
import invitations from "../../../assets/dashbordBtn/invitations.png";
import shoksamachar from "../../../assets/dashbordBtn/shoksamachar.png";
import jobs from "../../../assets/dashbordBtn/jobs.png";
import careerGuidance from "../../../assets/dashbordBtn/careerGuidance.png"
import artical from "../../../assets/dashbordBtn/artical.png"
import gatividhiya from "../../../assets/dashbordBtn/gatividhiya.png"
import samajSewa from "../../../assets/dashbordBtn/samajsewa.png"
import businessprofile from "../../../assets/dashbordBtn/businessprofile.png";
import registration from "../../../assets/dashbordBtn/registration.png";
import { Footer, Header, HeaderBoarder } from '../../../Components';
import { mandirLogo } from '../../../assets/MandirImg';
import Ads from '../../../assets/ads/shravan.jpg';
import { appdata } from '../../../constants/appSamajdata';

const { main_heading, Logo, swagat_sms } = appdata

export default function HomeDashboard() {
    const isAuthenticated = useSelector(state => state?.auth);
    const [isOpen, setOpen] = useState(true);

    useLayoutEffect(() => {
        const hasModalBeenShown = sessionStorage.getItem('hasModalBeenShown');
        if (hasModalBeenShown) {
            setOpen(false);
        }
    }, []);

    const images = useMemo(() => [
        { filename: "mandirdarshan", file: mandirdarshan, url: "/mandirdarshan" },
        { filename: "committee", file: committee, url: "/committee" },
        { filename: "members", file: members, url: "/members" },
        { filename: "doctor", file: doctor, url: "/doctor" },
        { filename: "bookdharmashala", file: bookdharmashala, url: "/bookdharmashala" },
        { filename: "programs", file: programs, url: "/programs" },
        { filename: "bloodbank", file: bloodbank, url: "/bloodbank" },
        { filename: "donations", file: donations, url: "/donations" },
        { filename: "achievements", file: achievements, url: "/achievements" },
        { filename: "samajcalender", file: samajcalender, url: "/samajcalender" },
        { filename: "rishtey", file: rishtey, url: "/rishtey" },
        { filename: "promotion", file: promotion, url: "/promotion" },
        { filename: "complaints", file: complaints, url: "/suzhav" },
        { filename: "invitations", file: invitations, url: "/invitations" },
        { filename: "shoksamachar", file: shoksamachar, url: "/shoksamachar" },
        { filename: "jobs", file: jobs, url: "/jobs" },
        { filename: "careerGuidance", file: careerGuidance, url: "/careerGuidance" },
        { filename: "businessprofile", file: businessprofile, url: "/businessprofile" },
        // { filename: "registration", file: registration, url: "/registration" },
        // { filename: "artical", file: artical, url: "/artical" },
        { filename: "gatividhiya", file: gatividhiya, url: "/gatividhiya" },
        // { filename: "samajSewa", file: samajSewa, url: "/samajSewa" },
    ], []);

    return (
        <>
            <HeaderBoarder />
            <ShowAds isOpen={isOpen} toggleModal={setOpen} />
            <div className='flex flex-wrap items-start px-5'>
                <div className='flex flex-wrap items-start sm:px-5 mb-5'>
                    {images.map((image, index) => (
                        <Link to={'/dashboard' + image.url} key={index}>
                            <img className='h-[6rem] md:h-[9rem]' src={image.file} alt={`${image.filename} ${index + 1}`} loading="lazy" />
                        </Link>
                    ))}
                    {isAuthenticated?.isLogin && isAuthenticated?.user?.userType === 1 && (
                        <div className='h-[5rem] md:h-[8rem] mt-2 mx-2 rounded-md bg-blue-700 text-white flex justify-center items-center'>
                            <Link className='text-bold px-5 py-3 text-center' to='/admin'>AdminDetails</Link>
                        </div>
                    )}
                    {isAuthenticated?.isLogin && isAuthenticated?.user?.userType === 1 && isAuthenticated?.user?.membershipID !== 135 && (
                        <div className='h-[5rem] md:h-[8rem] mt-2 mx-2 rounded-md bg-blue-700 text-white flex justify-center items-center'>
                            <Link className='text-bold px-5 py-3 text-center' to='/matrimony'>Matrimony dashboard</Link>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

const ShowAds = ({ isOpen, toggleModal }) => {
    const seen = () => {
        sessionStorage.setItem('hasModalBeenShown', 'true');
        toggleModal(false);
    }

    return (
        <>
            {isOpen && (
                <div
                    id="static-modal"
                    data-modal-backdrop="static"
                    tabIndex="-1"
                    aria-hidden="true"
                    className="fixed inset-0 flex justify-center items-center z-50 bg-gray-900 bg-opacity-50"
                >
                    <div className="relative flex flex-col items-center bg-white max-w-sm rounded-lg">
                        <button
                            onClick={seen}
                            type="button"
                            className="absolute top-0 right-0 text-3xl font-bold bg-transparent rounded-lg inline-flex justify-center items-center"
                        >
                            <svg
                                className="w-6 h-6"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className=' p-5 text-center flex items-center flex-col'>
                            <h1 className=' text-2xl text-red-500 font-bold'>{swagat_sms}</h1>
                            <img className="w-80 h-[100%]" src={Logo} alt="Advertisement" />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
