import React, { useEffect, useRef, useState } from 'react'
import { vaishaSamaj } from '../../constants/staticData';
import { samajReport } from '../../service/Services';
import { showErrorAlert } from "../../Components/ShowAlertCustome"
import LoadingModal from '../../Forms/LoadingModel';
import { useReactToPrint } from 'react-to-print';

export default function SamajReport() {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selected, setSelected] = useState('All Samaj Report');
    const [report, setReports] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    const printReport = useRef(null);

    useEffect(() => {
        const fetchGallerys = async () => {
            try {
                setLoading(true);
                const response = await samajReport(selected);
                console.log('gallery response:', response);

                if (!response?.success) {
                    showErrorAlert(response?.message)
                    return
                }
                const total = response?.data?.reduce((accu, item) => {
                    return accu + (item.count || 0);
                }, 0);
                setTotalCount(total);
                setReports(response?.data || []);
            } catch (error) {
                console.error('Error fetching gallerys:', error);
                setError('Error fetching gallerys. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchGallerys();
    }, [selected]);



    const handlePrint = useReactToPrint({
        documentTitle: "Print This Document",
        onBeforePrint: () => console.log("before printing..."),
        onAfterPrint: () => console.log("after printing..."),
        removeAfterPrint: true,
    });

    return (
        <div className='mb-20 px-5'>
            <LoadingModal show={loading} text='Loading' />
            <BySamaj selected={selected} setSelected={setSelected} />
            <div id='print-report' ref={printReport}>
                <div className='w-full text-center bottom-1 font-bold'>
                    <h3>अ.भा.वैश्य परिचय सम्मेलन 2024</h3>
                    <h3>मेंबर रजिस्ट्रेशन संख्या - समाज </h3>
                </div>
                <ReportTable reports={report} />
                <h1 className='mx-3 my-2'>Total = {totalCount}</h1>

            </div>
            <div className=' w-full flex justify-center'>
                <button onClick={() => { handlePrint(null, () => printReport.current); }} className=' text-white font-bold px-3 py-1 rounded-xl bg-blue-950'>Print</button>


            </div>
        </div>
    )
}

const ReportTable = ({ reports }) => {
    return (
        <div className="overflow-x-auto">
            <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden border">
                <thead>
                    <tr className="w-fulL text-sm bg-blue-950 text-white text-left">
                        <th className="py-1   px-2 text-left border-2">SR.NO</th>
                        {/* <th className="py-1  px-2 text-left border-2">SAMAJ NAME</th> */}
                        <th className="py-1  px-2 text-left border-2">GOTRA NAME</th>
                        <th className="py-1 px-2 text-left border-2">COUNT</th>
                    </tr>
                </thead>
                <tbody>
                    {reports.map((samaj, index) => {
                        const { _id, count } = samaj;
                        return (
                            <tr key={index} className="odd:bg-white text-sm even:bg-gray-50">
                                <td className="py-1 px-2 border-2 ">{index + 1}</td>
                                {/* <td className="py-1 px-2 border-2">{_id?.samaj}</td> */}
                                <td className="py-1 px-2 border-2">{_id?.gotra}</td>
                                <td className="py-1 px-2 border-2">{count}</td>
                            </tr>
                        );
                    })}

                </tbody>


            </table>
        </div>
    );
};





export function BySamaj({ selected, setSelected }) {

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);
    const selectsamaj = (samaj) => {
        setSelected(samaj);
        setIsOpen(false);
    };

    return (
        <div className="w-full flex  justify-center">
            <div className='w-64'>
                <label className="block text-sm font-medium leading-6 text-gray-900">Samaj Reports</label>
                <div className="relative mt-2">
                    <button
                        onClick={toggleDropdown}
                        className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    >
                        <span className="flex items-center">
                            {/* <img src={selected.avatar} alt="" className="h-5 w-5 flex-shrink-0 rounded-full" /> */}
                            <span className="ml-3 block truncate">{selected}</span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                            <svg
                                className="h-5 w-5 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7 7l3-3 3 3"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M7 13l3 3 3-3"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </span>
                    </button>


                    {isOpen && (
                        <ul className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {["All Samaj Report", "By Gotra"].map((samaj, index) => (
                                <li
                                    key={index}
                                    onClick={() => selectsamaj(samaj)}
                                    className={`relative cursor-default select-none py-2 pl-3 pr-9 ${selected === samaj ? 'text-white bg-indigo-600' : 'text-gray-900'
                                        }`}
                                >
                                    <div className="flex items-center">
                                        {/* <img src={samaj.avatar} alt="" className="h-5 w-5 flex-shrink-0 rounded-full" /> */}
                                        <span className={`ml-3 block truncate  `}>
                                            {samaj}
                                        </span>
                                    </div>

                                    {selected === samaj && (
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                                            <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm-1.293-9.707a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 011.414 1.414L10 12.414l-2.293-2.293a1 1 0 010-1.414z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </span>
                                    )}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
}

