import React from 'react';
import { FaPhone, FaComment, FaEnvelope, FaEye } from 'react-icons/fa';
import callporwal from "../assets/final cards for mayur/committee cards/callbutton.png"
import chatporwal from "../assets/final cards for mayur/committee cards/chatbutton.png"
import messageporwal from "../assets/dashbordBtn/messageporwal.png"
import viewporwal from "../assets/final cards for mayur/committee cards/profilebutton.png"
// import Logo from "../assets/final cards for mayur/committee cards/samajbutton.png"
import { mandirLogo } from '../assets/MandirImg';
import adhaksh from "../assets/adhaksh.jpeg"
import QR from "../assets/final cards for mayur/committee cards/scannerbutton.png"
import { appdata } from '../constants/appSamajdata';
const { main_heading, Logo, HeadPerson } = appdata
export default function CommitteeCard({ Committeemember }) {
    console.log("Committeemember", Committeemember)
    return (
        <div className=' relative w-full   md:w-[350px] xl:w-[450px]   shadow-2xl rounded-3xl h-100 m-3 p-2 xl:pb-4'>
            <div className='comittee-card-bg-color-top h-[80px] xl:h-[100px] rounded-t-3xl p-2 flex justify-between'>
                <img className='w-[4.5rem] h-[4.5rem] xl:w-[5.5rem]  xl:h-[5.5rem]  rounded-full ' src={Logo} alt="" loading="lazy" />
                <h1 className=' text-blue-950 font-bold text-[17px]  xl:text-[20px]    '>{main_heading}</h1>
                <img className="w-[4.5rem] h-[4.5rem] xl:w-[5.5rem] xl:h-[5.5rem] rounded-full  object-cover" src={HeadPerson} alt="" loading="lazy" />

            </div>
            <img className=' w-[4.5rem] h-[4.5rem] xl:w-[5.5rem]  xl:h-[5.5rem] rounded-full absolute ring-blue-700 ring-2 top-12 xl:top-16 left-[40%]  xl:left-[36%] object-cover object-center' src={Committeemember.member_profile} alt="" loading="lazy" />
            <div className='comittee-card-bg-color-bottom flex flex-col items-center mt-1 rounded-b-3xl justify-center h-[130px] xl:h-[150px] '>
                <h3 className="text-[1rem] xl:text-[1.3rem] font-bold text-white ">{Committeemember.member_name}</h3>
                <h4 className="text-[0.8rem] mb-3 xl:text-[1.2rem]  text-lg font-bold rounded-xl   bg-[#FFCC29] inline-block px-2 xl:px-3">
                    {Committeemember.member_post}
                </h4>

            </div>
            {/* mobile_number: "9827033232",
        whatsapp_number: "9827033232" */}

            <div className='flex justify-center items-center ms-4  mt-[-2rem]   w-full '>
                <div className='flex relative justify-center items-center w-[300px]'>
                    <img className='w-[3.8rem] h-[4rem] xl:w-[4.8rem] xl:h-[4.8rem] absolute   left-4 xl:left-0' src={QR} />
                    <div className='commeetti-card-btn-gb  flex justify-around gap-4 px-5 py-2  shadow-2xl bg-slate-300 rounded-2xl ring-2 ring-white'>
                        <img onClick={() => window.location.href = `tel:${Committeemember?.mobile_number}`} className='h-9 w-9 cursor-pointer' src={callporwal} alt="Call" loading="lazy" />
                        <img onClick={() => window.location.href = `https://wa.me/${encodeURIComponent(Committeemember?.mobile_number)}`} className='h-9 w-9 cursor-pointer' src={chatporwal} alt="View" loading="lazy" />
                        <img className='h-9 w-9' src={viewporwal} alt="View" loading="lazy" />
                    </div>
                </div>
            </div>
        </div>

    )
}

// {/* <div className=' w-[100vw]  sm:w-[400px]   border border-blue-200 shadow-2xl rounded-2xl flex flex-col     '>

// <div className="card-heade h-[7rem] rounded-2xl  relative flex flex-col items-center    bg-[#FFCC29]" >
//     <div className=' flex '>
//         <img className='w-24 h-24  ' src={Logo} alt="" loading="lazy" />
//         <h1 className=' text-blue-950 font-bold text-[16px]  sm:text-[20px]   '>श्री जांगडा पोरवाल पंचायती सभा</h1>
//     </div>
//     {/* <div className="  mt-[-18px]   ">
//         <img className='w-24 h-24 rounded-full object-cover object-center' src={Committeemember.member_profile} alt="" loading="lazy" />
//     </div> */}
// <img className='w-24 h-24 absolute top-16  rounded-full ring-2 ring-blue-600 object-cover object-center' src={Committeemember.member_profile} alt="" loading="lazy" />
// </div >

//     <div className="box-inner w-full px-3  flex flex-col items-baseline   mt-16">
//         <div className='text-center w-full my-2'>
//             <h3 className="text-[1.5rem] font-bold ">{Committeemember.member_name}</h3>
//             <h4 className="text-[1.2rem] text-lg font-bold rounded-xl text-white  bg-[#F37135] inline-block px-3">
//                 {Committeemember.member_post}
//             </h4>
//         </div>
// <div className='flex justify-center items-center   gap-3 mt-2  w-full mb-5'>
//     <img className=' w-24 h-24' src={QR} />
//     <div className='commeetti-card-btn-gb flex justify-around gap-5 mt-2 px-6 py-3 shadow-2xl bg-slate-300 rounded-3xl ring-2 ring-white'>
//         <p className='flex flex-col items-center cursor-pointer  ' onClick={() => window.location.href = `tel:${9827033232}`}> <img className='h-10' src={callporwal} alt="Call" loading="lazy" /> <span className='text-sm  font-bold tracking-tight text-gray-900'>Call</span></p>
//         <p className='flex flex-col items-center cursor-pointer' onClick={() => window.location.href = `whatsapp://send?phone=${9827033232}`}><img className='h-10' src={chatporwal} alt="Chat" loading="lazy" /> <span className='text-sm font-bold tracking-tight text-gray-900'>Chat</span></p>
//         {/* <p className='flex flex-col items-center cursor-pointer' onClick={() => window.location.href = `sms:${9827033232}`}> <img className='h-10' src={messageporwal} alt="Message" loading="lazy" /><span className='text-sm leading-7 font-bold tracking-tight text-gray-900'> Message</span></p> */}
//         <p className='flex flex-col items-center cursor-pointer'> <img className='h-10' src={viewporwal} alt="View" loading="lazy" /><span className='text-sm  font-bold tracking-tight text-gray-900'> View</span></p>
//     </div>
// </div>
//     </div>

// </div > * /}
