import React from 'react'
import { mandirLogo, mandir, mamdir2 } from '../assets/MandirImg'
import mandir1 from '../assets/mandir/photo1.jpg'
import mandir2 from '../assets/mandir/photo2.jpg'

const phots = [mandir1, mandir2, mandir, mamdir2]

export default function mandirCard() {
    return (
        <div className=' flex flex-wrap justify-center'>
            {/* <div className='h-100 m-2    min-w-[280px] max-w-[300px] p-5 border-r-5 border  rounded-xl shadow-2xl bg-white text-black  '>
                <img src={mandirLogo} />
            </div> */}

            <div className='h-100 m-2 min-w-[280px] max-w-[300px] p-2 border border-r-5 rounded-xl shadow-2xl bg-white text-black'>
                <img className='my-2 w-[500px] h-[500px] object-cover' src={mandir1} alt="Image 1" />
            </div>

            <div className='h-100 m-2 p-2 border border-r-5 rounded-xl shadow-2xl bg-white text-black'>
                <img className='my-2 w-[500px] h-[500px] object-cover' src={mandir2} alt="Image 2" />
            </div>

            <div className='h-100 m-2 min-w-[280px] max-w-[300px] p-2 border border-r-5 rounded-xl shadow-2xl bg-white text-black'>
                <img className='my-2 w-[500px] h-[500px] object-cover' src={mandir} alt="Image 3" />
            </div>

            <div className='h-100 m-2 p-2 border border-r-5 rounded-xl shadow-2xl bg-white text-black'>
                <img className='my-2 w-[500px] h-[500px] object-cover' src={mamdir2} alt="Image 4" />
            </div>

        </div>
    )
}
