import React, { useState } from 'react'

// committi cards
import card1 from "../../../assets/commitcard_certificate/1card.png"
import card2 from "../../../assets/commitcard_certificate/2card.png"
import card3 from "../../../assets/commitcard_certificate/3card.png"
import card4 from "../../../assets/commitcard_certificate/4card.png"
import card5 from "../../../assets/commitcard_certificate/5card.png"
import card6 from "../../../assets/commitcard_certificate/6card.png"
import card7 from "../../../assets/commitcard_certificate/7card.png"
import card8 from "../../../assets/commitcard_certificate/8card.png"
import card9 from "../../../assets/commitcard_certificate/9card.png"
import card10 from "../../../assets/commitcard_certificate/10card.png"
import card11 from "../../../assets/commitcard_certificate/11card.png"
import card12 from "../../../assets/commitcard_certificate/12card.png"
import card13 from "../../../assets/commitcard_certificate/13card.png"
import card14 from "../../../assets/commitcard_certificate/14card.png"
import card15 from "../../../assets/commitcard_certificate/15card.png"
import card16 from "../../../assets/commitcard_certificate/16card.png"
import card17 from "../../../assets/commitcard_certificate/17card.png"
// import card1 from "../../../assets/commitcard_certificate/1card.png"

// committi card certificte
import certificate1 from "../../../assets/commitcard_certificate/1certificate.jpeg"
import certificate2 from "../../../assets/commitcard_certificate/2certificate.jpeg"
import certificate3 from "../../../assets/commitcard_certificate/3certificate.jpeg"
import certificate4 from "../../../assets/commitcard_certificate/4certificate.jpeg"
import certificate5 from "../../../assets/commitcard_certificate/5certificate.jpeg"
import certificate6 from "../../../assets/commitcard_certificate/6certificate.jpeg"
import certificate7 from "../../../assets/commitcard_certificate/7certificate.jpeg"
import certificate8 from "../../../assets/commitcard_certificate/8certificate.jpeg"
import certificate9 from "../../../assets/commitcard_certificate/9certificate.jpeg"
import certificate10 from "../../../assets/commitcard_certificate/10certificate.jpeg"
import certificate11 from "../../../assets/commitcard_certificate/11certificate.jpeg"
import certificate12 from "../../../assets/commitcard_certificate/12certificate.jpeg"
import certificate13 from "../../../assets/commitcard_certificate/13certificate.jpeg"
import certificate14 from "../../../assets/commitcard_certificate/14certificate.jpeg"
import certificate15 from "../../../assets/commitcard_certificate/15certificate.jpeg"
import certificate16 from "../../../assets/commitcard_certificate/16certificate.jpeg"
import certificate17 from "../../../assets/commitcard_certificate/17certificate.jpeg"

import img_1 from "../../../assets/commitCard/img_1.png"
import img_2 from "../../../assets/commitCard/img_2.png"
import img_3 from "../../../assets/commitCard/img_3.png"
import img_4 from "../../../assets/commitCard/img_4.png"
import img_5 from "../../../assets/commitCard/img_5.png"
import img_6 from "../../../assets/commitCard/img_6.png"
// import img_7 from "../../../assets/commitCard/img_7.png"



const CommitteeMember = [card1, card2, card3, card4, card5, card6, card7, card8, card9, card10, card11, card12, card13, card14, card15, card16, card17]
const CommitteeMemberCertificate = [certificate1, certificate2, certificate3, certificate4, certificate5, certificate6, certificate7, certificate8, certificate9, certificate10, certificate11, certificate12, certificate13, certificate14, certificate15, certificate16, certificate17]
// const commitImg = [img_1, img_2, img_3, img_4, img_5, img_6,]
const commitImg = [img_1, img_2, img_3, img_4, img_5, img_6,]



export default function Committee() {
    const [isOpen, setOpen] = useState(false);
    const [certificateIndex, setCertificateIndex] = useState(null);

    const show_certificate = (index) => {
        setCertificateIndex(index)
        setOpen(true)
    }

    return (
        <>
            <ShowCertificate isOpen={isOpen} certificateIndex={certificateIndex} toggleModal={setOpen} />
            <div className='    flex flex-wrap gap-3 justify-center '>
                <div className='    flex flex-wrap  gap-0 sm:gap-3  justify-center '>
                    {/* <CommitteeCard /> */}
                    {CommitteeMember.map((img, index) =>
                    (<div key={index} className='  bg-transparent rounded-md shadow-all-sides items-center flex flex-col mt-4 '>
                        <img key={index} className='w-full   md:w-[350px] xl:w-[450px] ' src={img} loading='lazy' />
                        <button onClick={() => { show_certificate(index) }} className=' w-50 cursor-pointer px-3 py-1 rounded-3xl bg-blue-900 text-center text-white font-bold'>Certificate</button>
                    </div>
                    )
                    )}
                </div>
            </div>
            {commitImg.map((img, index) => (
                <img src={img} key={index} className=" img-fluid" />
            ))}

        </>
    )



}



const ShowCertificate = ({ isOpen, toggleModal, certificateIndex }) => {
    const seen = () => {

        toggleModal(false);
    }

    return (
        <>
            {isOpen && (
                <div
                    id="static-modal"
                    data-modal-backdrop="static"
                    tabIndex="-1"
                    aria-hidden="true"
                    className="fixed inset-0 flex justify-center items-center z-[999] bg-gray-900 bg-opacity-50"
                >
                    <div className="relative flex flex-col items-center bg-white max-w-sm rounded-lg">
                        <button
                            onClick={seen}
                            type="button"
                            className="absolute top-0 right-0 text-3xl font-bold bg-transparent rounded-lg inline-flex justify-center items-center"
                        >
                            <svg
                                className="w-6 h-6"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className=' p-5 text-center flex items-center flex-col'>
                            {/* <h1 className=' text-2xl text-red-500 font-bold'>{swagat_sms}</h1> */}
                            <img className="w-full h-auto" src={CommitteeMemberCertificate[certificateIndex]} alt="Advertisement" />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const IndoreGahoiSamajHelpline = () => {
    return (
        <div className=' mt-5'>
            <p><strong>इंदौर गहोई समवज हेल्पलवइन</strong></p>
            <ol style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
                <li>श्री सुधीर खाांगट 9893143116, संयोजक</li>
                <li>श्री राजेश दादे 9993304500, सह संयोजक</li>
                <li>श्री भुवनेश चनगोती 9425316236</li>
                <li>श्री नीरज सेठ 9685109060</li>
                <li>श्री केदार नीखरा 9575311335</li>
                <li>श्री राज सुहाने 9669229922</li>
                <li>श्री दवेेन्द्र िउदा 9826051175</li>
            </ol>
            <p>
                <strong>समवज में नर्र्वह बधवई पत्र की नजम्मेदवरी:</strong> श्री मुके श नबलैयव एर्ं श्री रवजेश दवदे
            </p>
            <p>
                <strong>कवयाकवरी सदस्यों के जन्मददन, नर्र्वह र्र्ागवंठ की बधवई की नजम्मेदवरी:</strong> श्री नीरज सेठ और श्री अनूप नपपरसवननयवं
            </p>
            <p>
                <strong>कवयाकवररणी से समन्र्यक:</strong> कव कवया उस प्रकोष्ठ के सभी सदस्यों से संर्वद रखनव और संयोजक की मदत करनव
            </p>
            <p>
                <strong>मुनिधवम और अंनतम दियवकमा के नलए पंनडत जी:</strong> श्री श्यवम शमवा 9893128713
            </p>
            <p>
                <strong>कवयाकवररणी से समन्र्यक:</strong> श्री अनूप चपपरसाचनया 9926061680 एर्ं श्री सुनील कनकन े9329425890
            </p>
        </div>
    );
};

const KavyaKaryaniSamanya = () => {
    return (
        <div className=' mt-5'>


            <strong>मनोनीत सदस्य</strong>
            <ol style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
                <li><em>उपवध्यक्ष</em> श्री महशे डगेंरे 9425063517 (जवर्रव कम्पवउंड)</li>
                <li><em>सांगठन मांत्री</em> श्री राम चबहारी कनकने 9425478737 (बजरंग नगर)</li>
                <li><em>प्रिार मांत्री</em> श्री नर्जय खदा 9424566316, 9425763283 (बसंत नर्हवर)</li>
                <li><em>साांस्कृचतक मांत्री</em> श्री राजकुमार सुहाने 9425048488 (मंगलमूर्तत नगर नर्लखव)</li>
                <li><em>सह सांगठन मांत्री</em> श्री आलोक िौधरी 9425763283 (महवलक्ष्मी)</li>
                <li><em>सह प्रिार मांत्री</em> श्री घनश्याम गेडा 9425060540 (खजरवनव सर्ककल)</li>
                <li><em>सह साांस्कृचतक मांत्री</em> श्री अरुण खैरा 9407122094 (िवणक्यपुरी सर्ककल केपवस)</li>
                <li><em>नर्शेर् आमंनत्रत सदस्य</em> श्री बी एम गुप्ता 9425917766 (खजरवनव मंददर)</li>
                <li><em>अध्यक्ष के सलवहकवर</em> डॉ. श्री कमलेश बडोचनया, 9826010890 (एलआईजी िौरवहव)</li>
            </ol>
            <p>डेंटल सजान DNS, TOTAL अस्पतवल</p>
        </div>
    );
};

const InvitedMembers = () => {
    return (
        <div className=' mt-5'>
            <strong>आमंनत्रत सदस्य</strong>
            <ol style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
                <li>श्री राजेन्द्र चिरोल्या 9669523041 (योजनव114)</li>
                <li>श्री प्रकाश चसजाररया 9425349950 (र्ैशवली)</li>
                <li>श्री आलोक खवररयव 9329479596 (ट्रेजर फैं टेसी, कै ट रोड)</li>
                <li>श्री ददशाांत नीखरा 7000071029 (अरवर्दो अस्पतवल)</li>
                <li>श्री महरें गुप्ता (कुिया) 8085561815 (सुदवमव नगर)</li>
                <li>श्री अचनल नगररया 9425315653 (योजनव 54)</li>
                <li>श्री योगेंर चबलैया 9926446091 (भवरत मवतव मंददर)</li>
                <li>श्री चवशाल लहाररया 9301933350 (योजनव 134, के सर बवग रोड)</li>
                <li>श्री रवजकु मवर इंदुरखयव 9039819437 (तीन इमली)</li>
                <li>श्री मुकेश मातेले 9993987406 (योजनव 134, के सर बवग रोड)</li>
                <li>श्री नीरज नोगरैया 9826293041 (ट्रेजर फैं टेसी, ए बी रोड)</li>
                <li>श्री शैलेन्द्र सराफ 9425057952 (एम्पवयर स्टेट बवईपवस)</li>
                <li>श्री ददलीप सोनी 7415333429 (संगम नगर)</li>
                <li>श्री रवजेंद्र प्रसवद गुप्तव 9893935463 (नशर्नसटी)</li>
            </ol>
        </div>
    );
};



const GavhoiSamajVision = () => {
    return (
        <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
            <h1 style={{ fontWeight: "bold", marginBottom: "20px" }}>गहोई समवज नर्ज़न 2024 -2027</h1>
            <p>गहोई समवज एप्लीकेशन फॉर मोबवाइल एंड लैपटॉप</p>
            <p>कवयािम सूिनव , कोर् नर्र्रण, ब्लड बैंक, नडनजटल आईडी कवडा, शोक समविवर , उपलनब्धयव, मेघवर्ी छवत्र एंड छवत्रवये</p>
            <p>र्ैर्वनहक जवनकवरी, नबज़नेस प्रोफवइल , सरकवरी अनधकवरी, डोनेशन, समवज नलस्ट, नर्नभन्न सनमनतयवं, ननमंत्रण</p>
            <p>सुझवर् , नर्ज्ञवपन</p>
            <p>गहोई समवज प्रोजेक्ट</p>
            <ul>
                <li>गहोई मुनि धवम रथ</li>
                <li>गहोई ननदेनशकव कव नडनजटल प्रकवशन</li>
                <li>गहोई पनब्लक स्कूल</li>
                <li>गहोई हॉस्टल</li>
                <li>गहोई इमेवजग सेंटर</li>
            </ul>

            <p><strong>गहोई वैश्य समाज र्ररष्ठ संघ</strong>: समवज की कवयाकवररणी को सलवह देनव और मदद करनव</p>
            <p><strong>र्ररष्ठ सलवहकवर मंडल</strong>: समवज के प्रोजेक्ट को मवगादशान देनव</p>
            <p><strong>कॉपोरेट प्रकोष्ठ</strong>: समवज के उद्यनमतव को मवगादशान देनव, बढ़वनव और मदद करनव</p>
            <p><strong>प्रशासचनक प्रकोष्ठ</strong>: समवज के प्रशवसननक कवयों कव मवगादशान करनव और मदद करनव</p>
            <p><strong>तकनीकी अचभनव प्रकोष्ठ</strong>: समवज के नर्द्यवर्तथयों को मवगादशान एर्ं सहवयतव प्रदवन करनव है</p>
            <p><strong>मीचडया प्रकोष्ठ</strong>: समवज की गनतनर्नधयों को समविवर पत्रों में समविवर प्रकवनशत करनव है</p>
            <p><strong>लीगल प्रकोष्ठ</strong>: समवज के सदस्यों को कवनूनी सलवह और सहवयतव प्रदवन करनव है</p>
            <p><strong>आई टी प्रकोष्ठ</strong>: समवज की नर्नभन्न गनतनर्नधयों को सोशल मीनडयव पर प्रकवनशत करनव है</p>
            <p><strong>मेचडकल प्रकोष्ठ</strong>: निदकत्सव नशनर्र, रिदवन नशनर्र कव आयोजन, समवज के सदस्यों को सलवह र् सहवयतव प्रदवन करनव है</p>
            <p><strong>साांस्कृचतक प्रकोष्ठ</strong>: दकसी भी सवंस्कृ नतक गनतनर्नधयों के दौरवन समवज को सलवह देनव, सहवयतव करनव और प्रबंधन करनव है</p>

            <ol>
                <li><strong>गहोई वैश्य समाज र्ररष्ठ संघ</strong> (21 Nos): श्री गोपाल चवलैया 9826536869 एर्ं श्री राजाराम सेरठया 9425153534</li>
                <li><strong>र्ररष्ठ सलवहकवर मंडल</strong> (04 Nos): श्री महशे डगेंरे 9425063517</li>
                <li><strong>लीगल प्रकोष्ठ</strong> (08 Nos): श्री महशे पहाररया 9826622927</li>
                <li><strong>कॉपोरेट प्रकोष्ठ</strong> (23 Nos): श्री सुधीर खाांगट 9893143116 एर्ं श्री राजेश दादे 9993304500</li>
                <li><strong>मीचडया प्रकोष्ठ</strong> (05 Nos): श्री मुकेश चवलैया 9893465418</li>
                <li><strong>प्रशासचनक प्रकोष्ठ</strong> (15 Nos): श्री राम चबहारी कनकने 9425478737 एर्ं श्री प्रकाश चसजाररया 9425349950</li>
                <li><strong>आई टी प्रकोष्ठ</strong> (06 Nos): श्री केदार नवथ नीखरा 9575311335</li>
                <li><strong>तकनीकी अचभनव प्रकोष्ठ</strong> (08 Nos): प्रो. अजय सेठ 9039610859</li>
                <li><strong>मेचडकल प्रकोष्ठ</strong> (28 Nos): श्री नीरज सेठ 9685109060 एर्ं श्री राज सुहाने 9669229922</li>
                <li><strong>साांस्कृचतक प्रकोष्ठ</strong> (22 Nos): श्री भुवनेश चनगोती 9425316236, श्री चशचशर कनकने 9669850970 एर्ं श्री दवेेन्द्र िउदा 9826051175</li>
            </ol>
        </div>
    );
};




const SamitiComponent = () => {
    return (
        <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
            <h1 style={{ fontWeight: "bold", marginBottom: "20px" }}>कवयाकवररणी से समन्र्यक</h1>
            <p>श्री गोपाल चवलैया 9826536869 एर्ं श्री राजाराम सेरठया 9425329977</p>
            <h2 style={{ fontWeight: "bold", marginBottom: "10px" }}>*गहोई वैश्य समाज र्ररष्ठ संघ* (21 Nos)</h2>
            <ol>
                <li>डॉ. जय कुमार सराफ MD Welcure मेनडसन फवमवा कं पनी 9826062054 संयोजक</li>
                <li>श्री प्रमोद नीखरा Rtd Army Officer MES 9425317526 सह संयोजक</li>
                <li>डॉ. रमाशांकर लहाररया Rtd DIG BSF 9425029821</li>
                <li>श्री गौरीशांकर सुहाने Rtd Bank Officer 9926216774</li>
                <li>डॉ. आर आर रेजा Rtd Prof 9009243829</li>
                <li>श्री पी. डी. वैश्य Rtd CE MPRDC 9826075398</li>
                <li>श्री पांकज गहोई Rtd LIC 9425064656</li>
                <li>श्री ददनेश गुप्ता सेठ Founder Brain Master 8889346022</li>
                <li>श्री रवमस्र्रूप नीखरव समवज सेर्ी 07314939544, 9893411429</li>
                <li>श्री पी. एल. नोगरैया Rtd Canara Bank 9826154587</li>
                <li>डॉ. रमेश नीखरा Hotel & Shopping Mall Owner 9993072015</li>
                <li>श्री रामेश्वर नाथ Ex Scientist BARC & Pearl Company 9826067585</li>
                <li>श्री सुनील बहरे Surveyor 9827031836</li>
                <li>श्री जगदीश नोगरैया Polite Paint 9826071028 / 9826337507</li>
                <li>श्री मवंगीलवल कनकने Grocery Business 9827534005</li>
                <li>श्री प्रेमिंद पहवररयव Rtd MPEB 9826641657</li>
                <li>श्री महेंद्र खांताल Transport 9425062485</li>
                <li>श्री रवजव कनकने Chardham Bus 9425056074</li>
                <li>श्री प्रदीप चपपरसाचनया Paint Industry 9826000064</li>
                <li>श्री अरुण कठल Builder & Developer 9630527456</li>
                <li>श्री रवजेश कु मवर सुहान, े Rtd Chief Manager LIC 9589050250, 7869856016</li>
            </ol>
        </div>
    );
};

const SamitiDetailsComponent = () => {
    return (
        <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
            <section>
                <h2 style={{ fontWeight: "bold", marginBottom: "10px" }}>कवयाकवररणी से समन्र्यक: श्री महशे डगेंरे 9425063517</h2>
                <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>र्ररष्ठ सलवहकवर मंडल (04 Nos)</h3>
                <ul>
                    <li>श्री जगदीश चिरोल्या Jewelry 9993351051</li>
                    <li>श्री अचनल खरया Morden and Nandni मेनडसन फवमवाकं पनी 9981128886</li>
                    <li>श्री एि. एन. पहाररया Rtd CE 9425082524</li>
                    <li>श्री राजीव रावत Supreme Roll 9826851966</li>
                </ul>
            </section>

            <section>
                <h2 style={{ fontWeight: "bold", marginBottom: "10px" }}>कवयाकवररणी से समन्र्यक: श्री महशे पहाररया 9826622927</h2>
                <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>*लीगल प्रकोष्ठ* (08 Nos)</h3>
                <ul>
                    <li>श्री राजेश लोचहया CS 9826063895 संयोजक</li>
                    <li>श्री िन्द्रप्रकवश कनकने CA 9827270527 सह संयोजक</li>
                    <li>श्री दीपक गुप्ता CA 9425096348</li>
                    <li>श्री रीतेश पहवररयव, CA 9425910666</li>
                    <li>श्री नीलेश गुप्ता CS 9826941425</li>
                    <li>श्री रोनहत गुप्तव नगररयव Advocate 9827285062</li>
                    <li>श्री रजत लोचहया Advocate 9977802528</li>
                    <li>श्री अनमत गुप्तव Advocate 9685788036</li>
                </ul>
            </section>

            <section>
                <h2 style={{ fontWeight: "bold", marginBottom: "10px" }}>कवयाकवररणी से समन्र्यक: श्री सुधीर खाांगट 9893143116 एर्ं श्री राजेश दाद े9993304500</h2>
                <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>*कॉपोरेट प्रकोष्ठ* (23 Nos)</h3>
                <ul>
                    <li>श्री भरत नीखरा Paint Industry 9425057286 संयोजक</li>
                    <li>श्री जगदीश चिरोल्या 9993351051 सह संयोजक</li>
                    <li>श्री अचनल इांदरुख्या Advisor फवमवाIndustry 9303380122 सह संयोजक</li>
                    <li>श्री राजेश सुहाने Fertilizer Industry 9425136973</li>
                    <li>श्री देर्ेंद्र सराफ, Wilcure मेनडसन फवमवाकं पनी 9425054435</li>
                    <li>श्री संजय सेठ जी सेठ एग्रो इंडस्ट्रीज़ उज्जैन 8120135080</li>
                    <li>श्री राजकुमार बरसैयाां Scrap Industry 9425066069</li>
                    <li>श्री सौरभ रुनसआ, Head कोटक मवहद्रव बैंक MP-CG, Behind 9039418628</li>
                    <li>श्री नननतन मोदी, मेनडसन फवमवाकं पनी 9415067462</li>
                    <li>डॉ. सांदीप गुप्ता Animal Food Industry 9826055773</li>
                    <li>श्री सुनील चपपरसाचनया Paint Industry and Builder 9425308681</li>
                    <li>श्री प्रदीप नोगरैया Paint Industry 9425132097, 7909901001</li>
                    <li>श्री प्रेम सेरठया Scrap Industry 9425066320, 9009044448</li>
                    <li>श्री दीपक नोगररयव Dry Fruits 9425058302</li>
                    <li>श्री सांजय पांसारी Silver foil Manufacturer for Agriculture 9425137064</li>
                    <li>श्री नरेश पंसवरी Hostel’s Owner 9425763624</li>
                    <li>श्री शैलेन्द्र पहाररया नशर्पुरम डेर्लपसाप्रवइर्ेट नलनमटेड 8839541535</li>
                    <li>श्री दीपक िपरव श्री कवक्षी फे रोटेक फे रो नमश्र धवतुऔर रसवयन 8947890483</li>
                    <li>श्री उमेश गुप्तव बृजपुररयव Plywood & Hardware Stockiest 9893734409</li>
                    <li>श्री दीपक गुप्तव मोर सुपवरी कव कवरोबवर 7771055999, 9993550579</li>
                    <li>श्री रवमजी नीखरव अनवमयव लॉनजनस्टक्स 9406556965</li>
                    <li>श्री राघव इांदरुख्या GIVE Indore 9926079994</li>
                    <li>श्री महक कठल Sheet Metal Component Manufacturer 7506178227</li>
                </ul>
            </section>
        </div>
    );
};

const CommitteeDetailsComponent = () => {
    return (
        <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
            <section>
                <h2 style={{ fontWeight: "bold", marginBottom: "10px" }}>
                    कवयाकवररणी से समन्र्यक: श्री मुकेश चवलैया 9893465418
                </h2>
                <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>*मीचडया प्रकोष्ठ* (05 Nos)</h3>
                <ul>
                    <li>श्री अिल नबस्र्वरी Danik Bhaskar & Prabhat Kiran 9425307896 संयोजक</li>
                    <li>श्री आलोक खररया Politics 9329479596 सह संयोजक</li>
                    <li>श्री राजेन्द्र सोनी Journalist 9827070242</li>
                    <li>श्री चशबाांशुखरया Nandni Laboratory 8959130001</li>
                    <li>श्री धीरेन्द्र रवर्त Printing Offset 9826906993</li>
                </ul>
            </section>

            <section>
                <h2 style={{ fontWeight: "bold", marginBottom: "10px" }}>
                    कवयाकवररणी से समन्र्यक: श्री राम चबहारी कनकन े9425478737 एर्ं श्री प्रकाश चसजाररया 9425349950
                </h2>
                <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>*प्रशासचनक प्रकोष्ठ* (15 Nos)</h3>
                <ul>
                    <li>श्री गोपाल कृष्ण करठल Rtd EE MC 9827012500 संयोजक</li>
                    <li>श्री प्रमोद कु मवर नछरौल्यव Rtd DSO 9425050578 सह संयोजक</li>
                    <li>श्री प्रमोद गुप्तव (सेठ) Rtd SBI 7415036797</li>
                    <li>श्री रवजेन्द्र खदा, Rtd Excise, टीकमगढ़ 8120051667</li>
                    <li>श्री प्रशवंत नीखरव SDO NVDA 9425046480, 7697168617</li>
                    <li>श्री कनपल गुप्तव AE MPEB 8817055928</li>
                    <li>श्री रवजकु मवर नैनव NVDA 7999927543, 9425963335</li>
                    <li>श्री पर्न बड़ेररयव Sale Tax 9425146029</li>
                    <li>श्री नरेंद्र (नौगररयव) गुप्तव Income Tax 9406718368</li>
                    <li>श्री अनभर्ेक मोर खवद्य ननरीक्षक 9575381414</li>
                    <li>श्री ददनेश नगररयव, JE MPEB 8989983652, 9425953892</li>
                    <li>सूबेदवर श्रीमती सीमव रवर्त 7828070970, 7049108468</li>
                    <li>श्री रोनहत ददररयव गुप्तव SI 9425077253</li>
                    <li>सुश्री रुनि महतेले ASI 7587629912, 7582070688</li>
                    <li>श्री नशर् कु मवर सोनी जी RED Cross Society 9300511896</li>
                </ul>
            </section>

            <section>
                <h2 style={{ fontWeight: "bold", marginBottom: "10px" }}>
                    कवयाकवररणी से समन्र्यक: श्री केदार नवथ नीखरा 9575311335
                </h2>
                <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>*आई टी प्रकोष्ठ* (06 Nos)</h3>
                <ul>
                    <li>श्री प्रदीप नबलैयव IT Industry 9560078834, संयोजक</li>
                    <li>श्री दीपक दादे IT Industry 7747012033 सह संयोजक</li>
                    <li>श्री चववेक नीखरा IT Industry 9755488428</li>
                    <li>श्री ज्ञवन प्रकवश गुप्तव IT Industry 9039367397</li>
                    <li>श्री अंदकत कु मवर गुप्तव सेठ IT Industry 8976227054</li>
                    <li>श्री रवघर्ेंद्र कनकने टीएल सवइबर इंफ्रव 7828012922</li>
                </ul>
            </section>
        </div>
    );
};



const KaryKarni = () => {
    return (
        <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
            <section>
                <h2 style={{ fontWeight: "bold", marginBottom: "10px" }}>
                    कवयाकवररणी से समन्र्यक: श्री प्रो. अजय सेठ 9039610859
                </h2>
                <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>*तकनीकी अचभनव प्रकोष्ठ* (8 Nos)</h3>
                <ul>
                    <li>श्री सुधीर िउदा EE CPWD 9827073701 संयोजक</li>
                    <li>श्री प्रभात कांथररया Scienstist F CAT Cryogenic 8103530535 सह संयोजक</li>
                    <li>श्री राकेश गुप्ता केट Scienstist F CAT 9425095345</li>
                    <li>श्री शनशकवंत सुहवने Scienstist F CAT 9826313969, 9827207669</li>
                    <li>डॉ पुनीत गुप्ता ननदेशक मेनडस्ववड क्लवसेस NEET केनलए 9425074587</li>
                    <li>श्री शरद लोनहयव Airtel & Actor 7738129402</li>
                    <li>श्री सुनील गुप्तव IIT Pulse Coaching 9893267088</li>
                    <li>श्री नंद गोपवल गुप्तव ननदेशक ग्लोबवस नर्ज्ञवन संस्थवन 9827295230</li>
                </ul>
            </section>

            <section>
                <h2 style={{ fontWeight: "bold", marginBottom: "10px" }}>
                    कवयाकवररणी से समन्र्यक: श्री भुवनेश चनगोती 9425316236, श्री चशचशर कनकन े9669850970 एर्ंश्री दवेेन्द्र िउदा 9826051175
                </h2>
                <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>*साांस्कृचतक प्रकोष्ठ* (22 Nos)</h3>
                <ul>
                    <li>श्री मनोज गुप्ता 9131148569 संयोजक, सवनहब जी मेनडको</li>
                    <li>श्री मदन मोहन सेठ 9981466805 सह संयोजक</li>
                    <li>श्री अंकु र खरयव 8411970718 सह संयोजक</li>
                    <li>श्री मयंक दवमले 9977215600</li>
                    <li>श्री सुरेश िउदा 9977897610</li>
                    <li>श्री अशोक कनकने 9300090456</li>
                    <li>श्री पीयूर् गहोई 9425055299</li>
                    <li>श्री मनोज गुप्तव (नबिपुररयव) 9981512541</li>
                    <li>श्री चववेक लोचहया 9977035586</li>
                    <li>श्री मंगेश पहवररयव 9827073192, 9039873192</li>
                    <li>श्री रवजेंद्र पहररयव 9575608298</li>
                    <li>श्री संतोर् नछरौल्यव 9179437052</li>
                    <li>श्री उमेश पांसारी 9826330838</li>
                    <li>श्री प्रशाांत कुिया 9827027100</li>
                    <li>श्री दवेेन्द्र कस्तावर 9300004037</li>
                    <li>श्री चववेक चवलैया 9977623456</li>
                    <li>श्री आनंद सुहवने 9302100729</li>
                    <li>श्री हररकृष्ण लोचहया 9826381404</li>
                    <li>श्री प्रशवंत गुप्ता 9712962189</li>
                    <li>श्री र्ीरेंद्र कु ियव 9977885357</li>
                    <li>श्री भरत र्ैश्य सेठ 9406827014</li>
                    <li>श्री संजीर् कनकने RTO Canteen 7024025708</li>
                </ul>
            </section>
        </div>
    );
};



const Medical = () => {
    return (
        <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
            <section>
                <h2 style={{ fontWeight: "bold", marginBottom: "10px" }}>
                    कवयाकवररणी से समन्र्यक: श्री नीरज सेठ 9685109060 एर्ं श्री राज सुहान े9669229922
                </h2>
                <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>*मेचडकल प्रकोष्ठ* (28 Nos)</h3>
                <ul>
                    <li>डॉ. श्री ओ. पी. कनकन ेPresident GPA 9826215604 संयोजक</li>
                    <li>डॉ. अजय रेजव एंडोिवइनोलॉनजस्ट (हवमोन सुपर स्पेशनलस्ट) नर्शेर्ज्ञ, CHL अस्पतवल 9826328341 सह संयोजक</li>
                    <li>डॉ. श्री गौरव गुप्ता िेस्ट दफनजनशयन और इंटरर्ेंशनल पल्मोनोलॉनजस्ट, िोइथरवम हॉनस्पटल 8839521437 सह संयोजक</li>
                    <li>डॉ रवके श गुप्तव कु ियव न्यूरोसजारी नर्भवग प्रमुख एमजीएम मेनडकल कॉलेज इंदौर 9425060151</li>
                    <li>डॉ आशुतोर् गुप्तव एमडी मेनडनसन GP 9425071281</li>
                    <li>डॉ. श्री अवधेश गुप्ता "मेनडकल सुपररडेंटेंट" आई.ओ.सी.आई सीएिएल अस्पतवल स्कीम 114 9977368033</li>
                    <li>डॉ. श्री सूययप्रकाश नगररया, कं सलटेंट स्पवइन र् आथोपेनडक सजान 9826712330</li>
                    <li>डॉ. श्री पांकज गुप्ता रेनडयोडवयग्नोनसस अमलतवस इंस्टीट्यूट ऑफ मेनडकल सवइंसेज, कोर्वध्यक्ष IMA 9893037898</li>
                    <li>डॉ नजतेन्द्र गुप्तव कु नियव, बवल रोग नर्शेर्ज्ञ और सवमवन्य निदकत्सक, भंडवरी हॉनस्पटल 8878329155</li>
                    <li>डॉ. ददलीप नीखरा सलवहकवर गोकु लदवस अस्पतवल 9893302644</li>
                    <li>डॉ. सौरभ चपपरसाचनया कं सल्टेंट पीनडयवरट्रनशयन एर्ं ननयोनेटोलॉनजस्ट, Care CHL हॉनस्पटल 8959627144</li>
                    <li>डॉ. नसद्धवथासरवफ कं सल्टेंट यूरोलॉनजस्ट, एंड्रोलॉनजस्ट और रीनल ट्रवंसप्लवंट सजान, िोइथरवम हॉनस्पटल 8234056139</li>
                    <li>डॉ. श्री राजेन्द्र चवस्वारी GP 8889788438</li>
                    <li>डॉ. महेंद्र कु मवर गुप्तव बजरांगडी सलवहकवर डेंटल सजान 9826263946</li>
                    <li>डॉ. ममता गुप्ता ब्लड बैंक के प्रमुख और प्रोफे सर पैथोलॉजी नर्भवग अमलतवस इंस्टीट्यूट ऑफ मेनडकल सवइंसेज 9826485575</li>
                    <li>डॉ सुरचभ राकेश गुप्ता ग्लूकोमव एर्ं मोनतयववबद नर्शेर्ज्ञ, र्ररष्ठ सलवहकवर िैररटेबल आई हॉनस्पटल 9425057392</li>
                    <li>डॉ दीचप्त एसपी नागररया प्रसूनत एर्ं स्त्री रोग नर्शेर्ज्ञ, प्रोफे सर इंडेक्स मेनडकल कॉलेज 9993807247</li>
                    <li>डॉ. दीपा गुप्ता प्रसूनत एर्ं स्त्री रोग नर्शेर्ज्ञ 9893623824</li>
                    <li>डॉ. प्रचमला नीखरा Pure आयुर्ेददक सलवहकवर (20 र्र्ासे) स्त्री रोग एर्ं त्र्िव नर्ज्ञवन में नर्शेर्ज्ञतव 9131968260</li>
                    <li>डॉ. अनुराधा गौरव गुप्ता प्रसूनत एर्ं स्त्री रोग (स्र्णा पदक नर्जेतव) 9993927141</li>
                    <li>डॉ. अपूर्वाजीतेन्द्र गुप्तव, मवइिोबवयोलॉनजस्ट, सलवहकवर रवजश्री अपोलो अस्पतवल 9479559059</li>
                    <li>डॉ ररतुसौरभ चपपरसाचनया आयुर्ेददक निदकत्सक GP 9009735789</li>
                    <li>डॉ सररता गुप्ता निदकत्सव अनधकवरी, सवमुदवनयक स्र्वस््य कें द्र बेटमव 7771007963</li>
                    <li>डॉ. मयांक गुप्ता GP Mediplus Hospital 9893014445</li>
                    <li>डॉ. सोमयतव गुप्तव बवल दंत निदकत्सक 9425816183</li>
                    <li>श्री दुगेश रवर्त Global Medical 8770955606</li>
                    <li>श्री आर केलोनहयव थोक दर्व व्यवपवर 9301306400</li>
                    <li>श्री अनभनर् सेठ Dental Equipments 990768888</li>
                </ul>
            </section>
        </div>
    );
};




















// const data = [
//     { designation: 'संयोजक', name: 'श्री सुधीर खांगट', contact: '9893143116', location: '' },
//     { designation: 'सह संयोजक', name: 'श्री राजेश दादे', contact: '9993304500', location: '' },
//     { designation: '', name: 'श्री भुवनेश चनगोती', contact: '9425316236', location: '' },
//     { designation: '', name: 'श्री नीरज सेठ', contact: '9685109060', location: '' },
//     { designation: '', name: 'श्री केदार नीखरा', contact: '9575311335', location: '' },
//     { designation: '', name: 'श्री राज सुहाने', contact: '9669229922', location: '' },
//     { designation: '', name: 'श्री दवेन्द्र िउदा', contact: '9826051175', location: '' },
//     { designation: 'समवज में नर्वह बधवई पत्र की नजम्मेदवरी', name: 'श्री मुकेश नबलैयव और श्री रवजेश दवदे', contact: '', location: '' },
//     { designation: 'कवयाकवरी सदस्यों के जन्मददन, नर्वह र्र्गवठ की बधवई', name: 'श्री नीरज सेठ और श्री अनूप नपपरसवननयवं', contact: '', location: '' },
//     { designation: 'कवयाकवररणी से समन्र्यक कवया उस प्रकोष्ठ के सभी सदस्यों से संर्वद रखनव और संयोजक की मदत करनव', name: '', contact: '', location: '' },
//     { designation: 'मुनिधवम और अंनतम दियवकमा के नलए पंनडत जी', name: 'श्री श्यवम शमवा', contact: '9893128713', location: '' },
//     { designation: 'कवयाकवररणी से समन्र्यक', name: 'श्री अनूप चपपरसाचनया', contact: '9926061680', location: '' },
//     { designation: '', name: 'श्री सुनील कनकन', contact: '9329425890', location: '' },
//     { designation: 'उपवध्यक्ष', name: 'श्री महेश डगेंरे', contact: '9425063517', location: 'जवर्रव कम्पवउंड' },
//     { designation: 'सांगठन मांत्री', name: 'श्री राम चबहारी कनकने', contact: '9425478737', location: 'बजरंग नगर' },
//     { designation: 'प्रिार मांत्री', name: 'श्री नर्जय खदा', contact: '9424566316, 9425763283', location: 'बसंत नर्हवर' },
//     { designation: 'साांस्कृचतक मांत्री', name: 'श्री राजकुमार सुहाने', contact: '9425048488', location: 'मंगलमूर्तत नगर नर्लखव' },
//     { designation: 'सह सांगठन मांत्री', name: 'श्री आलोक िौधरी', contact: '9425763283', location: 'महवलक्ष्मी' },
//     { designation: 'सह प्रिार मांत्री', name: 'श्री घनश्याम गेडा', contact: '9425060540', location: 'खजरवनव सर्ककल' },
//     { designation: 'सह साांस्कृचतक मांत्री', name: 'श्री अरुण खैरा', contact: '9407122094', location: 'िवणक्यपुरी सर्ककल केपवस' },
//     { designation: 'नर्शेर् आमंनत्रत सदस्य', name: 'श्री बी एम गुप्ता', contact: '9425917766', location: 'खजरवनव मंददर' },
//     { designation: 'अध्यक्ष के सलवहकवर', name: 'डॉ. श्री कमलेश बडोचनया', contact: '9826010890', location: 'एलआईजी िौरवहव' },
//     { designation: 'आमंनत्रत सदस्य', name: 'श्री राजेन्द्र चिरोल्या', contact: '9669523041', location: 'योजनव 114' },
//     { designation: '', name: 'श्री प्रकाश चसजाररया', contact: '9425349950', location: 'र्ैशवली' },
//     { designation: '', name: 'श्री आलोक खवररयव', contact: '9329479596', location: 'ट्रेजर फैं टेसी, कै ट रोड' },
//     { designation: '', name: 'श्री ददशाांत नीखरा', contact: '7000071029', location: 'अरवर्दो अस्पतवल' },
//     { designation: '', name: 'श्री महरें गुप्ता (कुिया)', contact: '8085561815', location: 'सुदवमव नगर' },
//     { designation: '', name: 'श्री अचनल नगररया', contact: '9425315653', location: 'योजनव 54' },
//     { designation: '', name: 'श्री योगेंर चबलैया', contact: '9926446091', location: 'भवरत मवतव मंददर' },
//     { designation: '', name: 'श्री चवशाल लहाररया', contact: '9301933350', location: 'योजनव 134, के सर बवग रोड' },
//     { designation: '', name: 'श्री रवजकु मवर इंदुरखयव', contact: '9039819437', location: 'तीन इमली' },
//     { designation: '', name: 'श्री मुकेश मातेले', contact: '9993987406', location: 'योजनव 134, के सर बवग रोड' },
//     { designation: '', name: 'श्री नीरज नोगरैया', contact: '9826293041', location: 'ट्रेजर फैं टेसी, ए बी रोड' },
//     { designation: '', name: 'श्री शैलेन्द्र सराफ', contact: '9425057952', location: 'एम्पवयर स्टेट बवईपवस' },
//     { designation: '', name: 'श्री ददलीप सोनी', contact: '7415333429', location: 'संगम नगर' },
//     { designation: '', name: 'श्री रवजेंद्र प्रसवद गुप्तव', contact: '9893935463', location: 'नशर्नसटी (नसल्र्र)' }
// ];

// const IndoreGahoiSamajHelpline = () => {
//     return (
//         <div>
//             <h2>इंदौर गहोई समवज हेल्पलवइन</h2>
//             <table>
//                 <thead>
//                     <tr>
//                         <th>पदनाम</th>
//                         <th>नाम</th>
//                         <th>संपर्क नंबर</th>
//                         <th>स्थान</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {data.map((item, index) => (
//                         <tr key={index}>
//                             <td>{item.designation}</td>
//                             <td>{item.name}</td>
//                             <td>{item.contact}</td>
//                             <td>{item.location}</td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//         </div>
//     );
// };


// return (
//     <div className='    flex flex-wrap gap-3 justify-center '>

//         <div className='flex flex-wrap justify-around mt-5 p-3   w-[100vw] bg-[#151B54]'>
//             {/* <Form data={data} label={"सदस्य का नाम"} />
//             <Form data={data} label={"समाज मैं पद"} />
//             <Form data={data} label={"मोबाइल नंबर"} /> */}
//             {/* <Form data={data} label={"lable"} /> */}
//         </div>



//         {/* <div className='    flex flex-wrap  gap-0 sm:gap-3  justify-center '>
//             <CommitteeCard />

//             {CommitteeMember.map((member, index) =>
//             (
//                 <CommitteeCard Committeemember={member} key={index} />
//             )
//             )}

//         </div> */}
//         {/* {members.map((member, index) =>
//         (
//             <MembersCard user={member} key={index} />
//         )
//         )} */}
//     </div>
// )
