

export const gotra = [
    'वासर', 'गोल', 'गांगल', 'बादल', 'कोच्छल', 'जैतल', 'वाच्छिल', 'काछिल', 'भाल', 'कोहिल', 'कासिव', 'सिंगल'
];






























export const doctorList = [
    { name: "डॉक्टर ओ.पी कनकले", specialization: "President-GPA" },
    { name: "डॉक्टर अजय रेजा", specialization: "Endocrynologist (Hormone super specialist) CHL Hospital" },
    { name: "डॉक्टर गौरव गुप्ता", specialization: "Chest physician and intervention palmonologist,choithram hospital" },
    { name: "डॉक्टर राकेश गुप्ता कुचया", specialization: "Head of Neurosurgery dept-MGM medical college indore" },
    { name: "डॉक्टर आशुतोष गुप्ता", specialization: "MD medicine GP" },
    { name: "डॉक्टर अवधेश गुप्ता", specialization: "Medical superintendent,IOCI CHL hospital Scheme 114" },
    { name: "डॉक्टर सूर्य प्रकाश नगरिया", specialization: "Consultant spine and orthopedic surgeon" },
    { name: "डॉक्टर पंकज गुप्ता", specialization: "Radiodiagrosis amaltas institute of medical sciences, koshadhyaksh IMA" },
    { name: "डॉक्टर जीतेन्द्र गुप्ता कुचिया", specialization: "child diseseases specialist and general physician - Bhandari Hospital" },
    { name: "डॉक्टर दिलीप नीखरा", specialization: "Consultant - Gokuldas Hospital" },
    { name: "डॉक्टर सौरभ पिपरसानियां", specialization: "consultant pediatrition and neonetologist, Care CHL hospital" },
    { name: "डॉक्टर सिद्धार्थ सराफ", specialization: "Urologist, endrologist and rinal transplant surgeon, Choithram Hospital" },
    { name: "डॉक्टर राजेंद्र विस्वारि", specialization: "GP" },
    { name: "डॉक्टर महेंद्र गुप्ता बजरंगडी", specialization: "Consultant, Dental Surgeon" },
    { name: "डॉक्टर ममता गुप्ता", specialization: "Head of blood bank and professor pathology dept, Amaltas institute of medical sciences" },
    { name: "डॉक्टर सुरभि राकेश गुप्ता", specialization: "Senior consultant - Glucoma and Retina specialist - Cheritable eye hospital" },
    { name: "डॉक्टर दीप्ती एसपी नागरिया", specialization: "professor Index medical college, pregnancy and women health specialist" },
    { name: "डॉक्टर दीपा गुप्ता", specialization: "pregnancy and women health specialist" },
    { name: "डॉक्टर प्रमिला नीखरा", specialization: "Pure ayurvedic consultant" },
    { name: "डॉक्टर अनुराधा गौरव गुप्ता", specialization: "pregnancy and women's health specialist(Gold medal winner)" },
    { name: "डॉक्टर अपूर्वा जीतेन्द्र गुप्ता", specialization: "Microbiologist, Consultant - Rajshri apolloe hospital" },
    { name: "डॉक्टर रितु सौरभ पिपरसानियां", specialization: "Ayurvedic Doctor - GP" },
    { name: "डॉक्टर सरिता गुप्ता", specialization: "Medical officer, Common head centre, betma" },
    { name: "डॉक्टर मयंक गुप्ता", specialization: "GP Mediplus Hospital" },
    { name: "डॉक्टर सौम्यता गुप्ता", specialization: "Child dental Doctor" },
    { name: "श्री दुर्गेश रावत", specialization: "Global Medical" },
    { name: "श्री आर के लोहिया", specialization: "Wholesale Medical business" },
    { name: "श्री अभिनव सेठ", specialization: "Dental Equipments" }
];


export const years = [2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050, 2051, 2052, 2053, 2054, 2055, 2056, 2057, 2058, 2059, 2060, 2061, 2062, 2063, 2064, 2065, 2066, 2067, 2068, 2069, 2070, 2071, 2072, 2073, 2074, 2075, 2076, 2077, 2078, 2079, 2080, 2081, 2082, 2083, 2084, 2085, 2086, 2087, 2088, 2089, 2090, 2091, 2092, 2093, 2094, 2095, 2096, 2097, 2098, 2099, 2100];
export const occupations = [
    "Family Business",
    "Private Job",
    "Government Job",
    'Housewife',
    "Other"
]

export const ageRange = ['18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '32', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60']