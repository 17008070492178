import React from 'react'
import MandirCard from '../../../Components/mandirCard'
import mamdirBg from "../../../assets/MandirImg/mandirBg.jpg"
import CameraStream from '../../../Components/CameraStream';



export default function Mandirdarshan() {


    const gateway = '192.168.1.1';  // Replace with your camera's IP address
    const port = '8000';           // Replace with your camera's port
    const path = 'stream';         // Replace with your camera's stream path

    return (
        <div className="App">
            <div className='w-screen h- overflow-y-auto bg-cover bg-no-repeat' style={{ backgroundImage: `url(${mamdirBg})`, backgroundAttachment: 'fixed' }}>
                <MandirCard />
            </div>
            {/* <h1 className="text-center text-2xl font-bold mb-4">Camera Stream</h1> */}
            {/* <CameraStream gateway={gateway} port={port} path={path} /> */}

        </div>
    );

    // return (
    //     <div className='w-screen h- overflow-y-auto bg-cover bg-no-repeat' style={{ backgroundImage: `url(${mamdirBg})`, backgroundAttachment: 'fixed' }}>
    //         <MandirCard />
    //     </div>
    // )
}
